// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-dynamic-category-js": () => import("./../src/pagesDynamic/category.js" /* webpackChunkName: "component---src-pages-dynamic-category-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biographie-js": () => import("./../src/pages/biographie.js" /* webpackChunkName: "component---src-pages-biographie-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expositions-js": () => import("./../src/pages/expositions.js" /* webpackChunkName: "component---src-pages-expositions-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

